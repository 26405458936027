
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const themeVariables = {
  spacingSteps: 8,

  // Colors
  primaryColor: '#E7392F',
  primaryHoverColor: '#BE2017',
  primaryActiveColor: '#FF483E',
  primaryActiveBgColor: '#ffedeb',
  errorColor: '#E7392F',
  petrol: '#0F4C5C',
  black: '#343434',
  grey: '#828282',
  greyTypo: '#595959',
  lightGrey: '#BDBDBD',
  white: '#ffffff',
  green: '#27AE60',
  backgroundLight: '#e6edee',
  backgroundGrey: '#eeeeee',
  redLight: 'rgba(231, 57, 47, 0.1)',

  // Transitions
  textColorHoverTransition: 'color 0.2s ease, fill 0.2s ease',
};



export const antdVariables = {
  '@blue': '#1890ff',
  '@purple': '#722ed1',
  '@cyan': '#13c2c2',
  '@gold': '#faad14',
  '@magenta': '#eb2f96',
  '@red': '#f5222d',
  '@orange': '#fa8c16',
  '@yellow': '#fadb14',
  '@green': '#40b249',
  '@primary-color': '@brand',
  '@info-color': '@blue',
  '@success-color': '@green',
  '@processing-color': '@blue',
  '@error-color': '@red',
  '@highlight-color': '@red',
  '@warning-color': '@gold',
  '@normal-color': '#d9d9d9',
  '@font-family': 'Barlow, digando-fallback',
  '@title-family': 'Barlow Condensed, digando-fallback',
  'code-family': 'SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace',
  '@line-height-base': '1.5',
  '@typography-title-font-weight': '600',
  '@typography-title-margin-top': '0',
  '@typography-title-margin-bottom': '0',
  '@font-variant-base': 'tabular-nums',
  '@font-feature-settings-base': 'tnum',
  '@padding-lg': '24px',
  '@padding-md': '16px',
  '@padding-sm': '12px',
  '@padding-xs': '8px',
  '@control-padding-horizontal': '@padding-sm',
  '@control-padding-horizontal-sm': '@padding-xs',
  '@border-color-split': 'hsv(0, 0, 91%)',
  '@border-color-inverse': '@white',
  '@border-width-base': '1px',
  '@border-style-base': 'solid',
  '@outline-blur-size': '1rem',
  '@outline-width': '1px',
  '@outline-color': '@text-color',
  '@background-color-light': 'hsv(0, 0, 98%)',
  '@background-color-base': 'hsv(0, 0, 96%)',
  '@disabled-color': 'fade(@black, 25%)',
  '@disabled-bg': '@background-color-base',
  '@disabled-color-dark': 'fade(#fff, 35%)',
  '@shadow-color': 'rgba(0, 0, 0, 0.3)',
  '@shadow-1-up': '0 -0.2em 0.2em @shadow-color',
  '@shadow-1-down': '0 0.2em 0.2em @shadow-color',
  '@shadow-1-left': '-0.2em 0 0.2em @shadow-color',
  '@shadow-1-right': '0.2em 0 0.2em @shadow-color',
  '@shadow-2': '0 4px 12px @shadow-color',
  '@select-item-selected-bg': '@background-color-light',
  '@select-item-active-bg': 'hsl(0, 78%, 96%)',
  '@tree-title-height': '28px',
  '@tree-node-selected-bg': 'none',

  /**
   * New defined
   */
  '@brand': themeVariables.primaryColor,
  '@white': themeVariables.white,
  '@black': themeVariables.black,
  '@petrol': themeVariables.petrol,
  '@box-shadow-base': 'none',
  '@border-radius-base': '3px',
  '@border-radius-sm': '3px',
  '@border-color-base': themeVariables.lightGrey,
  '@text-color': '@black',
  '@font-size-lg': '32px',
  '@font-size-base': '20px',
  '@font-size-sm': '16px',
  '@height-lg': '64px',
  '@height-base': '48px',
  '@height-sm': '32px',

  // Input
  '@input-bg': '#fffff', //white
  '@input-border-color': '#BDBDBD', //light grey
  '@input-hover-border-color': '@petrol', //petrol
  '@input-number-handler-border-color': '#BDBDBD',
  '@input-number-hover-border-color': '@petrol',

  // Button
  '@btn-border-radius-base': '16px',
  '@btn-border-radius-sm': '11px',
  '@btn-border-width': '2px',
  '@btn-default-border': '@brand',
  '@btn-font-size-sm': '@font-size-sm',
  '@btn-padding-horizontal-sm': '20px',
  '@btn-padding-horizontal-base': '30px',
  '@btn-padding-horizontal-lg': '15px',
  '@btn-default-color': 'themeVariables.primaryColor',
  '@btn-hover-color': 'theme.primaryHoverColor',
  '@btn-pressed-color': 'theme.primaryActiveColor',

  // Select
  '@select-border-color': '@input-border-color',

  //Slider
  '@slider-margin': '25px 19px 10px 19px',
  '@slider-handle-background-color': '@petrol',
  '@slider-handle-color': '@petrol',
  '@slider-handle-color-hover': '@petrol',
  '@slider-handle-color-focus-shadow': 'none',
  '@slider-handle-size': '27px',
  '@slider-dot-border-color': '@petrol',
  '@slider-handle-margin-top': '-11px',
  '@slider-handle-color-focus': '@petrol',
  '@slider-rail-background-color': '#BDBDBD',
  '@slider-rail-background-color-hover': '#BDBDBD',
  '@slider-track-background-color': '@primary-color',
  '@slider-track-background-color-hover': '@primary-color',

  // List
  '@list-item-padding': '12px 10px',
  '@list-item-padding-sm': '@list-item-padding',
  '@list-item-padding-lg': '@list-item-padding',
  '@list-header-background': themeVariables.backgroundLight,
  '@list-footer-background': themeVariables.backgroundLight,

  //Tabs
  '@tabs-highlight-color': themeVariables.petrol,
  '@tabs-hover-color': themeVariables.petrol,
  '@tabs-active-color': themeVariables.petrol,
  '@tabs-ink-bar-color': themeVariables.petrol,
  '@tabs-horizontal-margin': '0 30px 0 0',
  '@tabs-horizontal-padding': '18px 25px',

  //Carousel
  '@carousel-dot-width': '32px',
  '@carousel-dot-height': '2px',
  '@carousel-dot-active-width': '32px',

  // Collaps
  '@collapse-panel-border-radius': '0',
  '@collapse-header-bg': themeVariables.white,
  '@collapse-content-bg': themeVariables.white
};
