import React from 'react';
import { useTranslation } from 'next-i18next';
import { LogoutOutlined } from '@ant-design/icons';
import { Button, theme } from '@digando/react-component-library';
import styled from '@emotion/styled';
import {
  Menu as AntdMenu,
  Layout as AntdLayout,
  Space as AntdSpace
} from 'antd';
import { MenuProps } from 'antd/es/menu';
import { IRoutePath } from '../../interfaces';
import { MainMenuHeader } from './MainMenu/Header';
import { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';

interface IMenuProps {
  collapsed?: boolean;
  routes: IRoutePath[];
}

interface IMenuItem {
  label: string | React.ReactNode;
  key: string;
  icon: React.ReactNode;
  onClick: () => void;
}

export const MainMenu: React.FC<IMenuProps & MenuProps> = ({ collapsed = false, routes = [] }) => {
  const router = useRouter();
  const { t } = useTranslation('ScreenTitle');

  const handleMenuItemClick = (route: IRoutePath): void => {
    router.push(route.path);
  };

  const handleMenuItems = () => {
    const menuItems: IMenuItem[] = routes.map((route) => {
      return {
        label: t(route.routeName),
        key: route.path,
        icon: <StyledIcon>{route.icon}</StyledIcon>,
        onClick: () => handleMenuItemClick(route),
      }
    });

    menuItems.push({
      label: !collapsed ? <Button>{t('titles.logout')}</Button> : null,
      key: 'logout',
      icon: <StyledIcon><LogoutIcon/></StyledIcon>,
      onClick: () => signOut({ callbackUrl: '/login' }),
    });

    return menuItems;
  };

  return (
    <MainMenuLayout>
      <MainMenuHeader collapsed={collapsed} />
      <AntdLayout.Content>
        <MainMenuSpace direction="vertical">
          <Menu
            mode="vertical"
            selectedKeys={[router.route]}
            items={handleMenuItems()}
          />
        </MainMenuSpace>
      </AntdLayout.Content>
    </MainMenuLayout>
  );
};

const MainMenuLayout = styled(AntdLayout)`
  min-height: 100vh;
  height: 100vh;
`;

const MainMenuSpace = styled(AntdSpace)`
  width: 100%;
`;

const Menu = styled(AntdMenu)`
  background-color: rgba(0, 0, 0, 0);
  min-height: 100%;
  height: 100%;

  li {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 28px!important;

    span {
      min-width: unset !important;

      &:first-child {
        width: 24px;
      }

      &:last-child {
        margin-left: 0;
      }
    }

    button.ant-btn {
      span {
        width: auto;
      }
    }

    &:last-child {
      position: absolute !important;
      bottom: 2em;

      span.anticon {
        margin: 0 !important;
      }
    }
  }

  &.ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
    color: ${theme.primaryColor};
    background-color: rgba(0, 0, 0, 0);
  }
`;

const StyledIcon = styled.span`
  margin-right: 1em;
`;

const LogoutIcon = styled(LogoutOutlined)`
  margin: 3px 1em 3px 3px!important;
  font-size: 1em;
`;
