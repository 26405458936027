import {
  NormalizedCacheObject,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  ApolloClient
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { IApolloHeaders } from 'src/interfaces';
import getConfig from 'next/config';
import { NextRouter } from 'next/router';

export interface ISession {
  accessToken: string;
}

export interface IClientContext {
  session: ISession;
  router?: NextRouter;
}

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

const getHeaders = (session: ISession): IApolloHeaders => {
  const headers = {} as IApolloHeaders;

  if (session) {
    headers.Authorization = `Bearer ${session.accessToken}`;
  }

  return headers;
};

export const getApolloClient = (ctx: IClientContext): ApolloClient<NormalizedCacheObject> => {
  const authMiddleware = new ApolloLink((operation, forward) => {
    const headers = getHeaders(ctx.session);
    operation.setContext({ headers });

    return forward(operation);
  });

  const errorMiddleware = onError(({ graphQLErrors = [] }) => {
    if (0 < graphQLErrors.length) {
      let isAuthError = false;

      graphQLErrors.some(({ extensions }) => {
        isAuthError = 'invalid-jwt' === extensions?.code;

        return isAuthError;
      });

      if (isAuthError && typeof window !== 'undefined') {
        if (!ctx.router) {
          console.error('Try to redirect without router.');

          return;
        }

        ctx.router.push('/login');
      }
    }
  });

  const host = serverRuntimeConfig?.apiHost ?? publicRuntimeConfig.apiHost ?? window.location.host;
  const httpProtocol = serverRuntimeConfig?.useTLS ?? publicRuntimeConfig.useTLS ? 'https' : 'http';
  const httpLink = new HttpLink({ uri: `${httpProtocol}://${host}/v1/graphql` });
  const cache = new InMemoryCache();

  return new ApolloClient({
    link: authMiddleware.concat(errorMiddleware).concat(httpLink),
    cache: cache,
    ssrMode: undefined === typeof window
  });
};
