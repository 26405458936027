import React, { FC } from 'react';
import { Input as AntdInput } from 'antd';
import { InputProps } from 'antd/es/input';

export const Input: FC<InputProps> = props => {
  return <AntdInput {...props} />;
};

Input.defaultProps = {
  type: 'text',
  size: 'middle',
  disabled: false,
  allowClear: false,
};
